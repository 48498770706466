<template>
  <ion-list lines="full">
    <ion-item
      v-for="gameSession in sortedGameSessions"
      :key="gameSession.id"
      :router-link="{ name: 'GameSession', params: { id: gameSession.id } }"
    >
      <div class="item-wrap">
        <div class="top-row">
          <h2>{{ global.games.getById(gameSession.gameId).title }}</h2>
          <div class="date">{{ formatDate(gameSession.startedAt) }}</div>
        </div>

        <div class="configs">
          <div v-if="gameSession.config.club"  class="config">
            <h3>{{ gameSession.config.club }}</h3>
            <div>Club</div>
          </div>
          <div v-if="gameSession.config.distance" class="config">
            <h3>{{ gameSession.config.distance }}yds</h3>
            <div>Distance</div>
          </div>
          <div v-if="global.games.getById(gameSession.gameId).stats.shotCount" class="config">
            <h3>{{ gameSession.shots.length }}</h3>
            <div>Shots</div>
          </div>
          <div v-if="global.games.getById(gameSession.gameId).stats.xDev" class="config">
            <h3>{{ gameSession.data.xDev }}</h3>
            <div>xDEV</div>
          </div>
          <div v-if="global.games.getById(gameSession.gameId).stats.yDev" class="config">
            <h3>{{ gameSession.data.yDev }}</h3>
            <div>yDEV</div>
          </div>
          <div v-if="global.games.getById(gameSession.gameId).stats.points" class="config">
            <h3>{{ gameSession.data.points }}</h3>
            <div>Pts</div>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
import {
  IonList, 
  IonItem,
} from '@ionic/vue';

import dayjs from 'dayjs';

export default {
  name: 'GameSessionsList',
  inject: ['global'],
  components: {
    IonList,
    IonItem,
  },

  setup() {

    return {
    };
  },

  computed: {
    sortedGameSessions() {
      const gameSessions = [...this.global.gameSessions.state.gameSessions];
      
      return gameSessions.sort((a, b) => dayjs(b.startedAt).isBefore(dayjs(a.startedAt)) ? -1 : 1);
    },
  },

  created() {
  },

  data() {
    return {
      
    };
  },

  methods: {
    formatDate(val) {
      return dayjs(val).format('DD-MM-YYYY h:mma');
    },
  },
}
</script>

<style scoped lang="scss">
  ion-icon {
    margin-right: 1rem;
  }

  ion-item {
    border-bottom: 2px solid #eee;;
  }

  .item-wrap {
    flex: 1;
    padding: 0.5rem;
  }

  .top-row {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    h2 {
      font-weight: bold;
      flex: 1;
      font-size: 1.2rem;
      margin: 0;
    }

    .date {
      color: #666;
      margin-right: 0.25rem;
    }

  }

  .configs {
    display: flex;
    margin: 1rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    .config {
      margin: 0 1.5rem;

      h3 {
        font-size: 1.1rem;
        margin: 0;
      }

      > div {
        text-transform: uppercase;
        font-weight: bold;
        color: #666;
        font-size: 1rem;
      }
    }
  }

  .info {
    margin: 2rem 2rem 1rem;
    font-weight: bold;
    text-align: center;
  }
</style>