<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Game Sessions</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <div id="container">
        <GameSessionsList />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
} from '@ionic/vue';
import { golfSharp } from 'ionicons/icons';

import GameSessionsList from '@/components/game-sessions/GameSessionsList';

export default {
  name: 'GameSessionsHome',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,

    GameSessionsList,
  },

  setup() {

    return {
      golfSharp
    };
  },

  created() {
  },

  data() {
    return {
    };
  },

  methods: {
  },
}
</script>

<style scoped>
  
</style>